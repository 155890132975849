header{
    display: flex;
    width:100%;
    top: 0;
    background-color:#F0FFFF ;
}
header nav{
    display:flex;
    justify-content:space-around;
    width:70%;
    align-items:center;
    padding:10px;
}

header h1{
    width:30%;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start; 
    padding-left:20px;
    font-size:3rem;
    margin:20px;
}

nav a:hover{
    font-weight:bold;
}

#logout-style{
    color:#F0FFFF ;
    background-color: lightseagreen;
    width:60%;
    margin-right:20px;
    padding-top:5px;
    padding-bottom:5px;
    border-radius: 20px;
}

@media only screen and (max-width: 1280px) {
    header h1 {
      font-size:2rem;
    }
  }


@media only screen and (max-width: 890px) {
    header h1 {
      margin-right:10px;
    }
  }

  @media only screen and (max-width: 700px) {
    header{
        display: flex;
        flex-direction:column;
        width:100%;
        top: 0;
        background-color:#F0FFFF ;
    }
    header h1{
        width:100%;
        justify-content:center;
        padding-left:0px;
        font-size:3rem;
        margin:0px;
    }
    header nav{
        width:100%;  
    }

  }