.plant-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
}

img {
  width: 30%;
  margin-bottom: 10px;
}

button {
    border: none;
    color: lightseagreen;
    background-color: #F0FFFF;
    border-radius: 8%;
    width: 100px;
}