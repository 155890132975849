.home-container{
    width:100%;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:center;
    height:600px;
    background-image:url("https://images.unsplash.com/photo-1477554193778-9562c28588c0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80");
    background-size: cover;
    background-position:center;
    padding-bottom: 10%;

}

.home-container h2{
    background-color:rgba(255, 255, 255, 0.719);
    padding:30px;
    border-radius:10px;
    font-size: 3rem;
    width:350px;
}