footer {
   margin-top: 40px;
}
#footer-container {
    color:lightseagreen;
    width:100%;
    display:flex;
    flex-direction:column;
    background-color: rgba(255, 255, 255, 0.8);
    height: 20vh;
}
#footer-container address{
    display:flex;
    justify-content:space-around;
    width:100%;
}
