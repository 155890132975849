#plantform {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    /*border: 2px darkslategrey solid;*/
}

.plant-form-container{
    display: flex;
    flex-direction:column;
    background-color:rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    width: 80%;
    height: 80%;
    justify-content: center;
    align-items: center;
     margin: 10% auto;
    border: 2px darkslategrey solid;
    padding-bottom: 2.5%;
}
.form-group-inputs{
    display:flex;
    flex-direction:column;
    width: 22.5%;
    text-align:center;
}
button {
    background-color: #F0FFFF;
    border: none;
    color: lightseagreen;
    margin-bottom: 30px;
}

.form-group-inputs {

    display: flex;
    /*justify-content: center;*/
    align-items: center;
}














